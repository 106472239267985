
.reportWrraper{
    padding: 20px;
    height: 100%;
    clear: both;
}

.unittreesection {   
  float: left;
  width: 49%;  
  vertical-align:top;
  padding-right: 20px;  
  font-size: 14px;
}
@media (max-width: 1300px) {
  .unittreesection {   
    float: left;
    width: 49%;  
    vertical-align:top;
    padding-right: 10px;
    font-size: 14px;
  }
}

.accountRightSection {
  display: inline-block;
  width: 49%;  
  vertical-align:top;
  margin-top: 77px;
  font-size: 14px;
}

.groupingOption {
  margin: 20px 0px 0px 0px;
}

.accountDetails {
    font-weight: 700;
    color: "#D3D6DF";
    font-size: "14px";
    font-style: "italic";
    margin-top: "5px";
}

.inactiveContainer {
    background-color: #eee;
    border-radius: 5px;
    margin-top: 15px;
}

.firstNameInput div div {
  height: 40px;
}

.checkBox {
    padding:3px;
}

.sectionTitle {
    margin:  0px 0px 10px 0px; 
    color: #21394F;
 }

 .inputContainer {
    padding-top: 20px;
}

.inputContainer div div {
    height: 40px;
}


.inputContainermultiline {
  padding-top: 20px;
}
.inputContainermultiline div div {
  height: 100px;
}

.treeContianer {
  background-color: #eee;
  border-radius: 5px;
  margin-top: 20px;
  padding: 5px 15px;
}

.input {
    width: 100%;
    min-width: 300px !important;
    height: 40px;
}
.emailResetIcon {   
  margin-top: 5px;   
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.buttonSection {
  display: flex;
  flex-direction: row-reverse;
  margin-right: -5px;
  margin-top: 20px;
}

.passwordChecklist {
  padding-top: 10px;
}

.passwordChecklist ul li {
  font-size: 12px;
  font-weight: 600;
}

.passwordChecklist ul li span{
 margin-left:5px
}

.restartButton {
  border:1px solid #63C2FF !important
}

.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}

.guideMeButtonSpecific {
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:103px;
    right:35px;
    width: 24px;
    height: 24px;
    position:absolute;
  }
  .guideMeButtonback{
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:100px;
    right:100px;
    width: 24px;
    height: 24px;
    position:absolute;
  }
  @keyframes glowing {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 #63C2FF;
    }
    }
    
    @-webkit-keyframes glowing {
    0% {
      -webkit-box-shadow: 0 0 10px #63C2FF;
    }
    50% {
      -webkit-box-shadow: 0 0 40px #63C2FF;
    }
    100% {
      -webkit-box-shadow: 0 0 10px #63C2FF;
    }
    }


    
.expandContainer {  
  border-radius: 5px;
  margin-top: 10px;
  min-width: 510px;
  padding:10px;
  display: flex;
  justify-content: space-between;
  height: 15px;
  position: relative;
  top: -5px;
}