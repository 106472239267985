.wrapper {
    width: 500px;
    display: flex;
    position: fixed;
    top: 15px;
    right: 6px;
    height: 37px;
}

.userNameDiv {
    flex: 0 0 93%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.userName {
    float: right; 
    margin-top: 8px;
    margin-right: 5px;
}

/*MUI Overides */
.iconButton {
    color:#63C2FF !important; 
}

.iconButton:hover {
    color:#58ABE0 !important; 
}

.iconButton:focus {
    background-color: rgba(0,0,0,0) !important;
}
