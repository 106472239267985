.logoutContainer {
    position: fixed;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: #e5e5e5;  height: 100%;
    display: grid;
    place-items: center;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logoutModalContent {
    text-align: left;
}

.logoutModalContent > h3 {
    color: #fff;
    
}