.mainWrapper{  
  min-height: 230px;   
  height: auto;
  clear: both;
}
.Wrapper{   
  padding: 20px;
}
.inputContainer {
  margin-bottom: 10px;
}

.inputContainer a{
  color: #21394F;
}

.inputContainer  button
{
  margin: 0px !important;
  padding-left: 0px !important;
}
.ButtonContainer button
{
  margin: 0px !important;

}
.uniqueOption {
  margin: 20px 0px 0px 0px;
}
.formControl {
  width:100%;
}
.input {
  width: auto;
  height: 40px;
}
.formControl div svg{
  right: 12px;
}

.importfileContainer {
  background-color: #eee;
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px;
}
.importfileContainer span{ 
  padding: 3px 0 0 10px; 
  color: #21394F;
}
.sectionTitle {
  margin:  0px 0px 10px 0px; 
  color: #21394F;
}
.guideMeButton {
  display:flex;
  flex-direction:row-reverse;
  position:relative;
  top:-10px;
}
.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:103px;
  right:35px;
  width: 24px;
  height: 24px;
  position:absolute;
}
.labelText {  
  color: #21394F;
  font-weight: bold;
}
.label {
  color: #21394F;
}
.labelTextitalic {
  color: #21394F;
  font-style: italic;
}

.tableSection {
  display: flex;
  justify-content: center;
}

.tableContainer {
  width: 100%;
}

.tableHeadCellContainer {
  /* width: 90%; */
  display: inline-flex;
  align-items: center;
}
.dropZone { 
  padding: 9px;
  height: 110px;
  /* margin: 0rem 0 2rem; */
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  place-items: center;
  border: 3px dashed #c4c4c4;
  background-color: #eee;
}
.dropZoneOver {
  border: 3px solid #c4c4c4;
}
.dragZoneExit {
  border: 3px dashed #c4c4c4;
}

.dropZoneError {
  border: 3px solid red !important;
}
.dropZonePrompts {
  text-align: center;
}
.dropZoneThumb {
  height: 100%;
  
  background-color: #ccc;
  overflow: hidden;
  background-size: cover;
  background-repeat: none;
  background-position: center;
  border-radius: 5px;
  position: relative;
}
.dropZoneThumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  width: 100%;
  background: rgba(0,0,0,0.5);
}
.hiddenInput {
  display: none;
}
.dropAreaForm {
  align-items: center;
  margin-bottom: 20px;
}
.paginationControlsContainer {
  display: flex;
  justify-content: center;
}
.paginationAddedUsersControl {
  display: flex;
 justify-content: flex-end;
}
.paginationAddedUsersControl div {
  position:relative;
  right:'100vh'; 
}

.sortArrowHidden {
  opacity: 0 !important;
}

.sortArrowAccending {
  transform: rotate(0deg);
  transition: transform 600ms;
  display: grid;
  place-items: center;
}

.columnHeadArea {
  place-items: center;
  display: flex;
  flex-direction: column; 
  left: 5px;
  margin-left: 5px;
}

.sortArrowDescending {
  transform: rotate(180deg);
  transition: transform 600ms;
  display: grid;
  place-items: center;
}

.importAccountStatus {
  color: #767676;
  font-weight: 700;
  padding-top: 25px;
  font-size: 14px;
  margin-left: 20px
}
.importAccountSentStatus {
  color: #767676;
  font-weight: 700;
  padding-top: 15px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.importAccountFailedStatus {
  color: #ff0000;
  font-weight: 400;
  padding-bottom: 15px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.warningText svg{
  padding-top: 3px;
  margin-right: 5px;
}

.radiopadging{
  padding: 6px !important;
}

.radiodivpadging{
  padding-left: 6px !important;
}
.errorText svg{
  padding-top: 2px;  
  margin-right: 5px;
}
.warningText
{
  padding-top: 5px;
  color: #f9a23b;
}
.errorText
{
  padding-top: 5px;
  color: red;
}
.warningText a
{
  color: #f9a23b;
  text-decoration:underline;
  cursor:pointer; 
}
.errorText a
{
  color: red;
  text-decoration:underline;
  cursor:pointer; 
}
.activeaccountbg
{
  background-color:#e1ecfe;
}
.archiveerror
{
 background-color: #f9d8e1;
}

.errorReasontext
{ color: red;
}
.errorReasontext div
{
 color: red;  
 display: flex;
 float: left;
}
.errorReasontext div svg
{
  padding-top: 2px;
  padding-right: 2px;
}
.erroeFields
{
  
 background-color: #f9d8e1;
}
.wrraptxt
{
  word-wrap: break-word;
  width: 150px;
}
.erroeFields div
{
 float: left;
 background-color: #f9d8e1;
}
.erroeFields div svg
{
  padding-top: 2px;
  padding-right: 2px;
}
.saveIcon {
  width: 50px;
  height: 35px;
  margin-top: 5px;
  background: #F2B05C;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: center;
}

.returnIcon{
  color: #fff;
  width: 55px;
  height: 37px;
  background: #F2B05C;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-top: 5px;
  }

.cancelIcon {
  margin-right: 5px !important;
  color: #fff;
  width: 55px;
  height: 37px;
  margin-top: 5px;
  background: #F2B05C;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

@keyframes glowing {
0% {
transform: scale(1);
}
70% {
transform: scale(1);
box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
}
100% {
transform: scale(1);
box-shadow: 0 0 0 0 #63C2FF;
}
}

@-webkit-keyframes glowing {
0% {
-webkit-box-shadow: 0 0 10px #63C2FF;
}
50% {
-webkit-box-shadow: 0 0 40px #63C2FF;
}
100% {
-webkit-box-shadow: 0 0 10px #63C2FF;
}
}



.dropZonecertificate { 
  padding: 9px;
  height: 250px; 
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  place-items: center;
  border: 5px dashed #c4c4c4;
  background-color: #eee;
}

.highlightrowhover:hover {
  background-color: #F0F9FF !important;  
}