.columnDesc
{
width:20%;
}

.columnAction
{
width:20%;
}
.columnValue
{
width:50%;
}

.columnRevert
{
width:10%;
}

.inputmidsize {
  width: 100%;
  min-width: 300px !important;
}
.guideMeButtonSupport{
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:-45px;
  right:10px;
  width: 24px;
  height: 24px;
  position:relative;
  float: right;
}