.warningCard {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
    border-radius: 8px;
    height: 100%;
    position: relative;
}

.colorBar {
    /* background-color: #6A2876; */
    height: 5px;
    border-radius: 0 0 5px 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
