.container {
    width: 90%;
    padding: 10px 5% 0;
}

.questionBar {
    padding: 1px 10px;
}

.questionNumber {
    margin: 16px 0;
}

.questionContainer {
    padding: 10px 10px;
    min-height: 250px;
}

.topButtonPanel {
    display: inline-block;
    float: right;
    height: 100%;
    margin-top:3px;
}

.bottomButtonPanel {
    padding: 10px;
    text-align: right;
}

.loaderContainer {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
}

.navButtonsContainer {
    display: inline-flex
}