.logoutCard {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
    border-radius: 8px;
    min-width: 625px;
    min-height: 260px;
    position: relative;
    display: flex;
}

.logoutCard > div {
    flex: auto;
    /* border: 2px solid blue; */
}

.logoContainer {
    display: grid;
    max-width: 30%;
    place-items: center;
    border-right: 5px solid;
}

.logoContainer > img {
    max-width: 110px;
    height:auto;
}

.contentCard {
    background-color: #21394f;
    border-radius:  0 7px 7px 0;
}

.contentCard > div {
    display: grid;
    place-items: center;
    height: 100%;
}