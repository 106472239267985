
.reportWrraper{
    padding: 20px;
    height: 100%;
}

.unittreesection {   
    display: inline-block;
    width: 49%;  
    vertical-align:top;
    padding-right: 20px;
}

.rightSection {  
        display: inline-block;
        width: 49%;  
        vertical-align:top;
        margin-top: 92px;
}

.inactiveContainer {
    background-color: #eee;
    border-radius: 5px;
    margin-top: 15px;
}

.checkBox {
    padding:3px;
}

.sectionTitle {
    margin: 0px 0px 10px 0px; 
    color: #21394F;
 }

 .inputContainer {
    padding-top: 20px;
}

.inputContainer div div {
    height: 40px;
}

.treeContianer {
    background-color: #eee;
    border-radius: 5px;
    overflow-y: scroll;
    min-height: 100px;
    max-height: 540px;
    margin-top: 20px;
}

.input {
    width: 100%;
    min-width: 300px !important;
    height: 40px;
}

.emailResetIcon {   
  margin-top: 5px;   
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.buttonSection {
  display: flex;
  flex-direction: row-reverse;
  margin-right: -5px;
  margin-top: 20px;
}

.groupingOption {
      margin: 20px 0px 20px 0px;
}

.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}

.guideMeButtonSpecific {
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:103px;
    right:35px;
    width: 24px;
    height: 24px;
    position:absolute;
  }

  @keyframes glowing {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 #63C2FF;
    }
    }
    
    @-webkit-keyframes glowing {
    0% {
      -webkit-box-shadow: 0 0 10px #63C2FF;
    }
    50% {
      -webkit-box-shadow: 0 0 40px #63C2FF;
    }
    100% {
      -webkit-box-shadow: 0 0 10px #63C2FF;
    }
    }