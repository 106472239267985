.introContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin:3px;
    flex-direction: row;
}



.showWelcome {
    /* height: 145px; */
    padding: 2%;
    text-align: center;
    font-size:0.875rem ;
    color: #21394F;
}
.showPrivacyFeature {
  /* height: 145px; */
  padding: 2%;
  text-align: center;
  font-size:0.875rem ;
  color: #21394F;
}

.modalContentWrapper {
    overflow-y: auto;
    max-height: 79vh;
    margin-top: 1vh;
    padding: 3vw;
}

.modalContentWrapper::-webkit-scrollbar-track {
    background: rgb(226, 226, 226);
    border: 0px none #fff;
}

.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}

.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:-40px;
  right:10px;
  width: 24px;
  height: 24px;
  position:absolute;
}

.noResultsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: center;
  font-weight: 550;
}

@keyframes glowing {
0% {
  transform: scale(1);
}
70% {
  transform: scale(1);
  box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
}
100% {
  transform: scale(1);
  box-shadow: 0 0 0 0 #63C2FF;
}
}

@-webkit-keyframes glowing {
  0% {
    -webkit-box-shadow: 0 0 10px #63C2FF;
  }
  50% {
    -webkit-box-shadow: 0 0 40px #63C2FF;
  }
  100% {
    -webkit-box-shadow: 0 0 10px #63C2FF;
  }
}

  .inputContainer {
    margin-bottom: 15px;
    width: 100%;
  }
  .inputContainer > div > div 
  {
    width: 100% !important;
  }

  
.modalContentWrappercertificate {
  overflow-y: auto;
  max-height: 79vh;
  margin-top: 1vh;
  padding: 20px;
}

.modalContentWrappercertificate::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border: 0px none #fff;
}


.certificatetitleexpired{
  color: red;
}
