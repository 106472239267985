.arrowContainer {
    position: fixed;
    bottom: 50px;
    z-index: 11;
    width: 100%;
}

.arrow
{
  position: relative;
  bottom: -2rem;
  left: 50%;
  margin-left:-20px;
  width: 63px;
  height: 40px;
}

.arrow > svg {
    font-size: 4rem;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}