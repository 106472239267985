.detailText {
    display: block;
}

.progressContainer {
    display: flex;
    text-align: center;
}



.progressPercentage {
    font-size: 15px;
    letter-spacing: -0.5px;
    font-weight: 600;
}

.progressPercentageComplete {
    font-size: 15px;
    letter-spacing: -0.5px;
    font-weight: 700;
    color: #3bb18b
}

.progressText {
    font-size: 0.875rem;
    color: #21394F;
    height: 25px;
    text-align: center;
    margin-left: 20px;
}