.titleContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.titleContainer > div {
    display: block;
}

.buttonContainer {
    text-align: right;
    height: 100%;
}


.modalContentWrapper {
    overflow-y: auto;
    max-height: 79vh;
    margin-top: 1vh;
    padding: 3vw;
}