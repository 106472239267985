
.brandedCard {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
    border-radius: 8px;
    min-width: 48%;
    position: relative;
    min-height: 80px;
    display: flex;
    justify-content: center;
}

.searchCard {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
  border-radius: 8px;
  min-width: 48%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.searchContainer {
  display: flex;
  margin: 20px 0px;
}


.tableSection {
    display: flex;
    justify-content: center;
}

.tableContainer {
    width: 100%;
}

.tableHeadCellContainer {
    /* width: 90%; */
    display: inline-flex;
    align-items: center;
}

.columnHeadArea {
    place-items: center;
    display: flex;
    flex-direction: column;
    left: 5px;
    margin-left: 5px;
}

.reassignIcon {
    width: 50px;
    height: 35px;
    margin-top: 5px;
    background: #F2B05C;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
  }

  .deleteIcon {
    width: 55px;
    height: 37px;
    margin-top: 5px;
    background: red;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
  }

  .expandContainer {
    border-radius: 5px;
    margin-top: 10px;
    min-width: 510px;
    padding:10px;
    display: flex;
    justify-content: space-between;
}

.listField div div {
    height: 40px;
    width: 500px;
    background-color: white;
    margin-right: 1rem;
}

.input {
    width: 100%;
    min-width: 300px !important;
    height: 40px;
}

.lowerButtonPanel {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
 }

.scheduleNotFound {
    display:flex;
    justify-content:center;
     margin-top:10%;
}

.titleLink {
  color: black;
  cursor: pointer;

}

.titleLink:hover {
  color: blue;
  text-decoration: underline;
  
}


.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}


.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:-65px;
  right:10px;
  width: 24px;
  height: 24px;
  position:absolute;
}

@keyframes glowing {
0% {
  transform: scale(1);
}
70% {
  transform: scale(1);
  box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
}
100% {
  transform: scale(1);
  box-shadow: 0 0 0 0 #63C2FF;
}
}

@-webkit-keyframes glowing {
0% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
50% {
  -webkit-box-shadow: 0 0 40px #63C2FF;
}
100% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
}