.container {
    position: fixed;
    top: 13px;
    right: 25px;
}

.container_artrise360 {
  position: fixed;
  top: 75px;
  right: 65px;
}

.proceedIcon{
    width: 65px;
    height: 47px;
    background: #63C2FF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
    }


.returnIcon{
  width: 65px;
  height: 47px;
  background: #F2B05C;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }