
.reportWrraper{
    padding: 20px;
    height: 100%;
    clear: both;
}

.unittreesection {   
  display: inline-block;
  width: 49%;  
  vertical-align:top;
  padding-right: 20px;
  font-size: 14px;
}

@media (max-width: 1300px) {
  .unittreesection {   
    display: inline-block;
    width: 49%;  
    vertical-align:top;
    padding-right: 10px;
    font-size: 14px;
  }
}

.unittreesection h4 {
  font-size: 16px;
}
.accountRightSection {
  display: inline-block;
  width: 49%;  
  vertical-align:top;
  margin-top: 36px;
  font-size: 14px;
}

.restartButton {
  border:1px solid #63C2FF !important
}

.accountDetails {
    font-weight: 700;
    color: "#D3D6DF";
    font-style: "italic";
    margin-top: "5px";
}

.inactiveContainer {
    background-color: #eee;
    border-radius: 5px;
    margin-top: 15px;
}

.firstNameInput div div {
  height: 40px;
}

.checkBox {
    padding:3px;
}

.typographyFonts {
 font-size: 14px !important;
}

.MuiPaper-elevation1 {
  box-shadow: none;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px !important;
  min-height: 50px !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  padding-top: 0px !important;
}

.accountActiveText {
  color:#58ABE0;
  font-weight:bold;
}

.accountInactiveText {
  color:#f1484d;
  font-weight:bold;
}

.sectionTitle {
    margin:  0px 0px 10px 0px; 
    color: #21394F;
 }

 .inputContainer {
    padding-top: 20px;
}

.inputContainer div div {
    height: 40px;
}

.treeContianer {
  background-color: #eee;
  border-radius: 5px;
  margin-top: 20px;
  padding: 5px 15px;
}

.input {
    width: 100%;
    min-width: 300px !important;
    height: 40px;
}
.emailResetIcon {   
  margin-top: 5px;   
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.buttonSection {
  display: flex;
  flex-direction: row-reverse;
  margin-right: -5px;
  margin-top: 20px;
}

.passwordChecklist {
  padding-top: 10px;
}

.passwordChecklist ul li {
  font-size: 12px;
  font-weight: 600;
}

.passwordChecklist ul li span{
 margin-left:5px
}

.groupingOption {
  margin: 20px 0px 0px 0px;
}

.showWelcome {
  /* height: 145px; */
  padding: 2%;
  text-align: center;
  font-size:0.875rem ;
  color: #21394F;
}

.modalContentWrapper {
  overflow-y: auto;
  width: 760px;
  min-height: 400px;

}

.modalContentWrapper::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border: 0px none #fff;
}
.sendEmail {
  display:flex; 
  justify-content:flex-end;
}

.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}

.guideMeButtonSpecific {
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:100px;
    right:100px;
    width: 24px;
    height: 24px;
    position:absolute;
  }
  .tabContainer {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
    border-radius: 0px 0px 8px 8px;
    min-height: 80px;
  }
  @keyframes glowing {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 #63C2FF;
    }
    }
    
    @-webkit-keyframes glowing {
    0% {
      -webkit-box-shadow: 0 0 10px #63C2FF;
    }
    50% {
      -webkit-box-shadow: 0 0 40px #63C2FF;
    }
    100% {
      -webkit-box-shadow: 0 0 10px #63C2FF;
    }
    }