.mainContainer {
    display: flex;
    top: 0px;
}

.mainPanel {
    width: 100%;
    padding: 0px 20px 15px 20px;
    margin-bottom: 15px;
    margin-top: 70px;
    transition: margin-left 500ms;
}

.mainPanelContent {
    margin-top: 100px;
}