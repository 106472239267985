.treeContianer {
    background-color: #eee;
    border-radius: 5px;
    overflow-y: scroll;
    min-height: 100px;
    max-height: 540px;
    margin-top: 10px;
}

.treeContianer > ul {
    padding: 0px;
    margin: 0px;
}

.treeUl {
    list-style-type: none;
}

.treeLi {
    list-style-type: none;
}

.treeRow {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size:12px;
    margin-left: 15px;
}

.treeArrow{
    font-size:14px;
}