.accordion {
    color: white;
    height: 25px;
    padding: 9px;
    background-color: #21394F;
    overflow: hidden;
}

.accordion_MulSecModPlayer {
    color: white;
    height: 25px;
    padding: 9px;
    background-color: #21394F;
    overflow: hidden;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
}

.accordion:hover {
    cursor: pointer;
    background: #63C2FF;
}

.accordionNested {
    color: white;
    height: 25px;
    padding: 9px 9px 9px 20px;
    background-color:#0D2438;
    overflow: hidden;
}

.accordionNested:hover {
    cursor: pointer;
    background: #63C2FF;
}

.accordionNestedsmall {
    color: white;
    height: 25px;
    padding: 9px 9px 9px 9px;
    background-color:#0D2438;
    overflow: hidden;
}

.accordionNestedsmall:hover {
    cursor: pointer;
    background: #63C2FF;
}

.icon {
    margin-right: 9px;
}

.accordionSelected {
    cursor: pointer;
    background: #58ABE0;
    color: #fff;
    height: 25px;
    padding: 9px;
    overflow: hidden;
}

#sideMenuWrapper {
    margin-top: 70px;
}

#sideMenuWrapper_MulSecModPlayer {
    margin-top: 70px;
    z-index: 9999;
}

.exapandedAccordionContainer {
    transition: height 600ms;
    overflow: hidden;
}

.exapandedAccordionContainer .accordionSelected{
    padding: 9px 9px 9px 20px;
}

.accordionArrow {
    transform: rotate(0deg);
    transition: transform 600ms;
    display: grid;
    place-items: center;
}

.accordionArrowExpanded {
    transform: rotate(180deg);
    transition: transform 600ms;
    display: grid;
    place-items: center;
}

.sideMenu {
    position: fixed;
    background-color: #21394f;
    height: 100%;
    width: 220px;
    z-index: 1;
    transition: width 500ms;
}

.sideMenuClosed {
    position: fixed;
    background-color: #21394f;
    height: 100%;
    width: 44px;
    z-index: 1;
    transition: width 500ms;
}

.sideMenu_MulSecModPlayer {
    position: fixed;
    background-color: #21394f;
    height: 100%;
    width: 220px;
    z-index: 9999;
    transition: width 500ms;
}

.sideMenuClosed_MulSecModPlayer {
    position: fixed;
    background-color: #21394f;
    height: 100%;
    width: 44px;
    z-index: 9999;
    transition: width 500ms;
}

.closeSideMenuBtn {
    height: 30px;
    width: 30px;
    background-color: #63C2FF;
    position: fixed;
    top: 76px;
    z-index: 2;
    left: 207px;
    border-radius: 30px;
    transition: left 500ms;
}

.closeSideMenuBtnClosed {
    height: 30px;
    width: 30px;
    background-color: #63C2FF;
    position: fixed;
    top: 76px;
    z-index: 2;
    left: 32px;
    border-radius: 30px;
    transition: left 500ms;
}

.sideMenuSpacer {
    height: 70px;
    width: 10px;
}

.closeSideMenuBtn_MulSecModPlayer {
    height: 30px;
    width: 30px;
    background-color: #63C2FF;
    position: fixed;
    top: 76px;
    z-index: 10000;
    left: 207px;
    border-radius: 30px;
    transition: left 500ms;
}

.closeSideMenuBtnClosed_MulSecModPlayer {
    height: 30px;
    width: 30px;
    background-color: #63C2FF;
    position: fixed;
    top: 76px;
    z-index: 10000;
    left: 32px;
    border-radius: 30px;
    transition: left 500ms;
}

.sideMenuSpacer_MulSecModPlayer {
    height: 70px;
    width: 10px;
    z-index: 9999;
}

.buttonContent {
    display: flex;
}

.closeSideMenuBtn, .closeSideMenuBtnClosed {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.closeSideMenuBtnClosed:hover, .closeSideMenuBtn:hover{
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #58ABE0;
}

.sideMenuTextIconContainer {
    display: inline-flex;
    align-items: center;
}

.sideMenuText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    font-size: 14px;
    transition: width 600ms;
}

.sideMenuText_MulSecModPlayer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    font-size: 14px;
    transition: width 600ms;
    border-bottom: 1px solid grey;
}

.sideMenuText_MulSecModPlayer_Selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    font-size: 14px;
    font-weight: bolder;
    transition: width 600ms;
    border-bottom: 1px solid grey;
}

.sideMenuText_MulSecModPlayer_MouseHoverSelected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    font-size: 18px;
    font-weight: bolder;
    transition: width 600ms;
    border-bottom: 1px solid grey;
}

.sideMenuText_MulSecModPlayer_MouseHover {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    font-size: 18px;
    font-weight: bolder;
    transition: width 600ms;
    border-bottom: 1px solid grey;
}

.arrowContainer {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    transform: rotate(0deg);
    transition: transform 600ms;
}

.arrowContainerClosed {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    transform: rotate(180deg);
    transition: transform 600ms;
}

.arrowContainer_MulSecModPlayer  {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    transform: rotate(0deg);
    transition: transform 600ms;
    z-index: 10000;
}

.arrowContainerClosed_MulSecModPlayer  {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    transform: rotate(180deg);
    transition: transform 600ms;
    z-index: 10000;
}

.sideMenuDivider {
    background-color: #111d27;
    color: white;
    font-weight: 550;
    font-size: 14px;
    padding-left: 1rem;
    padding: 5px;
}


.sideMenuDivider_MulSecModPlayer {
    background-color: #111d27;
    color: white;
    font-weight: 550;
    font-size: 14px;
    padding-left: 1rem;
    padding: 5px;
    z-index: 9999;
}

.sideMenuDividerClosed {
    background-color: red;
    color: white;
    font-weight: 500;
    font-size: 14px;
    padding-left: 1rem;
}

.sideMenuDividerClosed_MulSecModPlayer {
    background-color: red;
    color: white;
    font-weight: 500;
    font-size: 14px;
    padding-left: 1rem;
    z-index: 9999;
}




.groupingOption svg {
    margin-top: -8px  !important;
}

.formControl div svg{
    right: 12px;
    height: 40px;
    color: #fff !important;
}

.inputSelect {
    width: 60%;
    min-width: 210px !important;
    height: 60px;
}

.textField div input{
    color: #fff !important;
}

.textField div button span svg{
    color: #fff !important;
}

.formControl div select{
    color: #fff !important;
}


.accordionNestedAPI {
    color: #21394F;
    height: 25px;
    padding: 9px 9px 9px 20px;
    overflow: hidden;
}

.accordionNestedsmallAPI {
    color: #21394F;
    height: 25px;
    padding: 9px 9px 9px 9px;
    overflow: hidden;
   
}
.accordionSelectedAPI {
    cursor: pointer;
    font-weight: bold;    
    color: #21394F;
    height: 25px;
    padding: 9px 9px 9px 20px;
    overflow: hidden;
}