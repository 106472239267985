.iframe {
    display: block;
    table-layout: auto;
    border-collapse: collapse;
    color: #000;
    overflow: none;
    background-color: #fff;
    height: 100vh;
    width: 100%;   /* to remove extra scrollbar on all course page */
    border: 0;
}