.treeContianer {
    background-color: #eee;
    border-radius: 5px;
    margin-top: 20px;
    padding: 5px 15px;
}

.courseTreeContianer {
    background-color: #eee;
    border-radius: 5px;
    overflow-y: scroll;
    min-height: 100px;
    max-height: 400px;
    margin-top: 20px;
    padding: 5px 15px;
}
.pastcourseTreeContianer {
    background-color: #eee;
    border-radius: 5px;
    overflow-y: scroll;
    height: 200px;
    margin-top: 20px;
    padding: 5px 15px;
}
.policyContainer {
    background-color: #eee;
    border-radius: 5px;
    overflow-y: scroll;
    min-height: 100px;
    max-height: 400px;
    margin-top: 20px;
}

.colorBar {
    /* background-color: #6A2876; */
    height: 5px;
    border-radius: 0 0 5px 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.blankContent {
    font-weight: 700; 
    color: #D3D6DF; 
    font-size:14px;
    font-style: italic;
    margin-top:5px;
}
.blankContentwithcolor {
    font-weight: 700; 
    color: #63C2FF; 
    font-size:14px;
    font-style: italic;
    margin-top:5px;
}
.icon_and_text_container {
    display: flex;
    width: auto;
    height: auto;
    position: relative;
    padding: 10px;
}
.icon_and_text_container_leftshift {
    display: flex;
    width: auto;
    height: auto;
    position: relative;
    padding: 10px;
    margin-left: -20px;
}
.icon_and_text_container_icon {
    flex: 1;
    padding: 10px;
    width: 20px;
    height: 20px;
    max-width: 20px;
}
.icon_and_text_container_text {
    flex: 1;
    padding: 10px;
    display: flex;
    align-items: center;
    width: auto;
    height: 20px;
    margin-top: -2px;
    margin-left: -20px;
}

@media (max-width: 4320px) {
    .children_centering_container {
        position: relative;
        padding: 0% 20% 0% 20%;
        margin-bottom: 5px;
    }
 }

 @media (max-width: 500px) {
     .children_centering_container {
         position: relative;
         padding: 0%;
         margin-bottom: 5px;
     }
  }

.blankContentwithgreencolor {
    font-weight: 700; 
    color: green; 
    font-size:14px;
    font-style: italic;
    margin-top:15px;
}
.brandedCard {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
    border-radius: 8px;
    width:450px;
    position: relative;
    min-height: 80px;
    animation-duration: 2.5s;
    animation-name: slidein;
    animation-iteration-count: 1;
    margin-left: 66%;
}

.unitButtonSpanStyle {
    margin-left: 5px;
    font-size: 12px;
}

@media (max-width: 1300px) {
    .unitButtonSpanStyle {
        display: none;
    }
  }
  
.resultCard {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
    border-radius: 8px;
    width:550px;
    position: fixed;
    min-height: 110px;
    animation-duration: 2.5s;
    animation-name: slidein;
    animation-iteration-count: 1;
    bottom: 50px;
    right: 22px;   
}


.resultCardposition {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
    border-radius: 8px;
    width:550px;
    min-height: 110px;
    animation-duration: 5.5s;
    position: absolute;
    bottom: 50px;
    right: 22px;      
}

@keyframes slidein {
    0% {
      margin-left: 100%;
      opacity: 1;      
    }  
    80% {
      margin-left: 66%;
      opacity: 1;
    }
    100% {
        margin-left: 66%;
        opacity: 1;
    }
}
.courseTreeContianer > ul {
    padding: 0px;
    margin: 0px;
}
.pastcourseTreeContianer > ul {
    padding: 0px;
    margin: 0px;
}
.returnIcon{
  width: 65px;
  height: 47px;
  background: #F2B05C;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }

  .resetIcon {
    width: 50px;
    height: 40px;
    margin-top: 5px;
    /* border: 1px solid #63C2FF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25); */
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
  }

.emailResetIcon {   
    margin-top: 5px;   
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }

.proceedIcon{
    width: 65px;
    height: 47px;
    background: #63C2FF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
}

.treeContianer > ul {
        padding: 0px;
        margin: 0px;
}   


/* Filter buttons override material Style.js settings  */
.buttonSelected {
    margin: 0px !important;
    background: #58ABE0 !important;
}

.button {
    margin: 0px !important;
}

.sectionTitle {
   margin: 0px 0px 10px 0px; 
   color: #21394F;
}

.sectionTitleWithPadding {
   margin: 0px 0px 10px 0px; 
   color: #21394F;
   padding: 20px 0px 0px 0px;
}

.lowerButtonPanel {
   display: flex;
   flex-direction: row-reverse;
   margin-right: -5px;
   margin-top: 20px;
}

.exportBtnContainer {
    display: inline-block;
    justify-content: space-between;
    width: 100%;
}
.reportContainer
{
    width: 84%;
    float:left;
}

.reportDetailContainer {
    font-size: 14px;
    display: inline-block;
    margin-right: 40px;
    vertical-align:top;
}

.reportDetailTitle {
    display: block;
    padding-top: 5px;
    font-size: 12px;
    color: #21394F;
}
.reportExportContainer {
    font-size: 14px;
    display: inline-block;   
    width :16%;
    text-align: right;
}
.reportDetail {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #21394F;
}

.reportPageTitleSection {
    padding: 20px;
}

.paddingTop {
    padding: 20px 0px 0px 0px !important;
}

.noPaddingTop {
    padding: 0px 0px 0px 0px !important;
}

.paddingTop10 {
    padding: 10px 0px 0px 0px !important;
}

.tableSection {
    display: flex;
    justify-content: center;
}

.tableContainer {
    width: 100%;
}

.tableHeadCellContainer {
    /* width: 90%; */
    display: inline-flex;
    align-items: center;
}

.tableHeadCellContainerLong {
    /* width: 90%; */
    display: inline-flex;
    align-items: center;
    width: 200px;
}

.tableHeadCellContainerShort {
    /* width: 90%; */
    display: inline-flex;
    align-items: center;
    width: 60px;
}

.unittreesection {   
    display: inline-block;
    width: 49%;  
    vertical-align:top;
    padding-right: 20px;
}
.coursetreesection {   
    display: inline-block;
    width: 49%;  
    vertical-align:top;
}
.coursetreesection_shifteddown {   
    display: inline-block;
    width: 49%;  
    vertical-align:top;
    padding-top: 94px;
}

.equalunittreesection {   
    display: inline-block;
    width: 49%;  
    vertical-align:top;
}

.equalcoursetreesection {   
    display: inline-block;
    width: 49%;  
    vertical-align:top;
}
.leftdivSection {   
    padding-right: 20px;
}
.rightdivSection {   
    padding-left: 20px;
}

.paginationControlsContainer {
    display: flex;
    justify-content: center;
}

.paginationAddedUsersControl {
    display: flex;
   justify-content: flex-end;

}

.paginationAddedUsersControl div {

    position:'relative';
    right:'100vh';
   
}

.sortArrowHidden {
    opacity: 0 !important;
}

.sortArrowAccending {
    transform: rotate(0deg);
    transition: transform 600ms;
    display: grid;
    place-items: center;
}

.columnHeadArea {
    place-items: center;
    display: flex;
    flex-direction: column; 
    left: 5px;
    margin-left: 5px;
}

.sortArrowDescending {
    transform: rotate(180deg);
    transition: transform 600ms;
    display: grid;
    place-items: center;
}
.reportTitle{
    position:relative;
    padding-top: 23px;
     margin-left: 10px;
}
.reportWrraper{
    padding: 20px;
    height: 100%;
}
/* Inport box */


.input {
    width: 100%;
    min-width: 300px !important;
    height: 40px;
}

.expiredIn {
    width: 100%;
    min-width: 300px !important;
    height: 40px;
}

.expiredIn label{
    margin-top: 7px;
}

.inputBold {
    width: 100%;
    min-width: 300px !important;
    height: 40px;
    font-weight: bolder;
    font-size: large;
}

.inputContainer {
    padding-top: 20px;
}

.inputContainerDatePicker {
    background-color: #CFCFCF;
    border-radius: 5px;
    padding-left: 0px;
}

.inputContainer div div {
    height: 40px;
}

.savedContent {
    color: rgb(75, 202, 129);
    padding: 1rem;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 16px;
}
.warningContent {
    color: #F2B05C; 
    padding: 1rem; 
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 16px;
}

.errorContent {
    color: #ff0000; 
    padding: 1rem; 
    font-weight: bold;
    margin-top: 0px;
    font-size: 16px;
    margin-bottom: 0px;
}

.textContent {
    text-align: left;
    margin-left: 1rem;
    padding-bottom: 15px;
    font-size: 14px;
}

.daysField {
    padding-top: -10px;
}

.daysField div div {
    height: 40px;
    position: relative; 
    top: 10px;
}

.listField div div {
    height: 40px;
    background-color: white;
}

.selectDropdown {
    width: 100%;
    min-width: 300px !important;
    background-color: white;
    height: 56px;
    padding: 10px 0 18.5px 14px;
}
.inputarea {
    width: 100% !important;
    height: 100px !important;
    min-width: 300px !important;
}
.MuiOutlinedInput-input
{
    margin:0px !important;
    padding: 0px !important;
}

/*.MuiSelect-select:focus
{
    background-color: white;
}
.MuiSelect-outlined:focus
{
    background-color: '#FFF';
}*/

.groupingOption {
    margin: 20px 0px 20px 0px;
}

.formControl {
    width:100%;
}

.formControl div svg{
    right: 12px;
}

.checkBox {
    padding:3px;
}

.emailReportContainer {
    width: 100%;
}

.emailReportContainer tbody tr {
    vertical-align: initial;
}

.noResultsCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    justify-content: center;
    font-weight: 550;
}

.leftSection {
    flex-grow: 2;
}

.rightSection {
    flex-grow: 2;
    margin-right: 10px;
}

.childContainer {
    background-color: #eee;
    border-radius: 5px;
    margin-top: 10px;
    min-width: 535px;
    padding:10px;
}


.expandContainer {
    background-color: #eee;
    border-radius: 5px;
    margin-top: 10px;
    min-width: 510px;
    padding:10px;
    display: flex;
    justify-content: space-between;
    height: 15px;
    position: relative;
    top: -5px;
}

.sortArrowAccending {
    transform: rotate(0deg);
    transition: transform 600ms;
    display: grid;
    place-items: center;
}

.sortArrowDescending {
    transform: rotate(180deg);
    transition: transform 600ms;
    display: grid;
    place-items: center;
}

.listField {
   
  flex-grow: 2;
}

.loaderContainer {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
}

/* Split the Popup in half */
.popupsplit {
    display: inline-block;
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow: hidden;
    padding-top: 10px;
}
  
/* Control the left side of Popup*/
.popupsplitleft {
    vertical-align:top;
    display: inline-block;
    height: 100%;
    width: 50%;
    /*position: fixed;
    z-index: 1;*/
    top: 0;
    overflow: hidden;
    padding-top: 10px;
    left: 0;
    margin-left: -10px;
}
  
/* Control the right side of Popup */
.popupsplitright {
    vertical-align:top;
    display: inline-block;
    height: 100%;
    width: 50%;
    /*position: fixed;
    z-index: 1;*/
    top: 0;
    overflow: hidden;
    padding-top: 10px;
    /*right: 0;*/
    left:0;
    margin-left: -10px;
}

.treeContianer {
    background-color: #eee;
    border-radius: 5px;
    overflow-y: scroll;
    min-height: 100px;
    max-height: 540px;
    margin-top: 20px;
}

.treeContianer > ul { 
    padding: 0px;
    margin: 0px;
}

.PolicyUl {
    list-style-type: none;
    margin:0px;
    padding:5px 15px;
}

.treeLi {
    list-style-type: none;
}

.treeRow {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size:12px;
}


.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}

.guideMeButtonSpecific_UserModuleAccess {
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:100px;
    right:100px;
    width: 24px;
    height: 24px;
    position:absolute;
  }


.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:103px;
  right:35px;
  width: 24px;
  height: 24px;
  position:absolute;
}

.modalTitleContainer {
    display: flex;
}

.exitButtonContainer {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.courseName {
    font-weight: 600;
}

.passed {
    color: #3bb18b
}

.failed {
    color: #fa0200
}

.totalRow {
    font-weight: bold;
    font-size: 13px;
}

.grandtotalRow {
    font-weight: bold;
    font-size: 13px;
    border: solid 2px;
}

.backButtonSpecific {
    border-radius: 100%;
    top:87px;
    right:62px;
    width: 24px;
    height: 24px;
    position:absolute;
}

.inactiveContainer {
    background-color: #eee;
    border-radius: 5px;
    margin-top: 15px;
}

.radioUnitTree {
    margin-left: -30px;
    margin-top: -20px;

}

.radioUnitTree  .PrivateSwitchBase-root-11 {
    padding: 5px !important;
  }
  


.error {
    color: #ff0000;
}

/* Create three equal columns that floats next to each other */
.column {
    float: left;
}
  
/* Clear floats after the columns */
.row:after {
    clear: both;
}


@keyframes glowing {
0% {
  transform: scale(1);
}
70% {
  transform: scale(1);
  box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
}
100% {
  transform: scale(1);
  box-shadow: 0 0 0 0 #63C2FF;
}
}

@-webkit-keyframes glowing {
0% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
50% {
  -webkit-box-shadow: 0 0 40px #63C2FF;
}
100% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
}
 

.deleteIcon {
    width: 55px;
    height: 37px;
    background: red;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
  }


  .confirmationmodelCloseIcon{
        color: #fff;
        width: 55px;
        height: 37px;
        background: #F2B05C;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        margin-top: 5px;
}