.reportContainer
{
    width: 90%;
    float:left;
} 

.reportDetailTitle {
    display: block;
    padding-top: 5px;
    font-size: 12px;
    color: #21394F;
}
.reportExportContainer {
    font-size: 14px;
    display: inline-block;   
    width :10%;
    text-align: right;
}

.exportBtnContainer {
    display: inline-block;
    justify-content: space-between;
    width: 100%;
}

.reportDetailContainer {
    font-size: 14px;
    display: inline-block;
    margin-right: 40px;
    vertical-align:top;
}
.reportDetail {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #21394F;
}

.reportPageTitleSection {
    padding: 20px;
}

.noResultsCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    justify-content: center;
    font-weight: 550;
}


.criteriaSection {
    margin: 10px 0px; 
    border-radius: 6px 6px 6px 6px; 
    border:0px dashed transparent; 
    position: inherit
}


.tableSection {
    display: flex;
    justify-content: center;
}

.tableContainer {
    width: 100%;
}

.tableHeadCellContainer {
    /* width: 90%; */
    display: inline-flex;
    align-items: center;
}

.columnHeadArea {
    place-items: center;
    display: flex;
    flex-direction: column;
    left: 5px;
    margin-left: 5px;
}

.sendEmail {
    display:flex; 
    justify-content:flex-end;
    /* margin-right:2.5%;  */
    padding:15px;
}

.emailStatus {
    color: #767676;
    font-weight: 700;
    padding-top: 25px;
    font-size: 14px;
    margin-left: 20px
}
.emailSentStatus {
    color: #767676;
    font-weight: 700;
    padding-top: 15px;
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.emailFailedStatus {
    color: #ff0000;
    font-weight: 400;
    padding-bottom: 15px;
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.guideMeButtonSpecific_ViewSendEmail {
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:100px;
    right:100px;
    width: 24px;
    height: 24px;
    position:absolute;
  }

.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}

.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:105px;
  right:35px;
  width: 24px;
  height: 24px;
  position:absolute;
}

.backButtonSpecific {
  border-radius: 100%;
  top:93px;
  right:62px;
  width: 24px;
  height: 24px;
  position:absolute;
}

.proceedIcon{
    width: 65px;
    height: 47px;
    background: #63C2FF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
    }

    
.returnIcon{
    width: 65px;
    height: 47px;
    background: #F2B05C;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
    }

.loaderContainer {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
}