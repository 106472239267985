/* Filter buttons override material Style.js settings  */
.buttonSelected {
    margin: 0px !important;
    background: #58ABE0 !important;
}

.button {
    margin: 0px !important;
}

.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}

.guideMeButton {
  display:flex;
  flex-direction:row-reverse;
  position:relative;
  top:-10px;
}

.filterButtonContainer {
  margin-bottom: 15px;
  margin-top: 15px;
}


.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:-40px;
  right:10px;
  width: 24px;
  height: 24px;
  position:absolute;
}

@keyframes glowing {
0% {
  transform: scale(1);
}
70% {
  transform: scale(1);
  box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
}
100% {
  transform: scale(1);
  box-shadow: 0 0 0 0 #63C2FF;
}
}

@-webkit-keyframes glowing {
0% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
50% {
  -webkit-box-shadow: 0 0 40px #63C2FF;
}
100% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
}

