.wrapper {
    /* min-width: 300px; */
    /* min-height: 300px; */
    text-align: center;
    /* margin-top: -10%; */
    padding: 30px;
}

.boldText {
    font-weight: bold;
}

.passedText {
    color: green;
    font-weight: bold;
}

.failedText {
    color: red;
    font-weight: bold;
}