.rowrule{
  content: "";
  clear: both;
  display: table;
}

[class*="columnrule_"] {
  float: left;
  padding: 0px;
}


/* For mobile phones: */
[class*="columnrule_"] {
  width: 100%;
}


@media only screen and (min-width: 600px) {
  /* For tablets: */
  .columnrule_s_1 {width: 8.33%;}
  .columnrule_s_2 {width: 16.66%;}
  .columnrule_s_3 {width: 25%;}
  .columnrule_s_4 {width: 33.33%;}
  .columnrule_s_5 {width: 41.66%;}
  .columnrule_s_6 {width: 49.5%;}
  .columnrule_s_7 {width: 58.33%;}
  .columnrule_s_8 {width: 66.66%;}
  .columnrule_s_9 {width: 75%;}
  .columnrule_s_10 {width: 83.33%;}
  .columnrule_s_11 {width: 91.66%;}
  .columnrule_s_12 {width: 100%;}
}
@media only screen and (min-width: 1024px) {
  /* For desktop: */
  .columnrule_1 {width: 8.33%;}
  .columnrule_2 {width: 16.66%;}
  .columnrule_3 {width: 25%;}
  .columnrule_4 {width: 33.33%;}
  .columnrule_5 {width: 41.66%;}
  .columnrule_6 {width: 50%;}
  .columnrule_7 {width: 58.33%;}
  .columnrule_8 {width: 66.66%;}
  .columnrule_9 {width: 73%;}
  .columnrule_10 {width: 83.33%;}
  .columnrule_11 {width: 91.66%;}
  .columnrule_12 {width: 100%;}
}
@media only screen and (min-width: 1024px) {
  /* For desktop: */
  .columnrule_l1 {width: 2.9%;}
  .columnrule_l6 {width: 48.5%;}
}
