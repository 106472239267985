.courseName {
    font-weight: 600;
}

.passed {
    color: #3bb18b
}

.failed {
    color: #fa0200
}

.exportBtnContainer {
    display: inline-block;
    justify-content: space-between;
    width: 100%;
}
.reportContainer
{
    width: 76%;
    float:left;
}

.reportDetailContainer {
    font-size: 14px;
    display: inline-block;
    margin-right: 40px;
    vertical-align:top;
}

.reportDetailTitle {
    display: block;
    padding-top: 5px;
    font-size: 12px;
    color: #21394F;
}
.reportExportContainer {
    font-size: 14px;
    display: inline-block;   
    width :16%;
    text-align: right;
}
.reportDetail {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #21394F;
}

.reportPageTitleSection {
    padding: 20px;
}