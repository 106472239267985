
.mainWrapper{  
  min-height: 130px;   
  height: auto;
  clear: both;
  margin-top: 20px;
}
.Wrapper{   
  padding: 20px;
}

.unitName {
  background-color: #fff;
} 

.input {
  width: 100%;
  min-width: 300px !important;
  height: 40px;
}

.guideMeButton {
  display:flex;
  flex-direction:row-reverse;
  position:relative;
  top:-10px;
}

.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:103px;
  right:35px;
  width: 24px;
  height: 24px;
  position:absolute;
}
.unittreesection {   
  float: left;
  width: 100%;  
  vertical-align:top;
  padding-right: 20px;  
  font-size: 14px;
}

.childContainer {
  border:solid 1px #c4c4c4;
  border-radius: 5px;
  margin-top: 10px;
  min-width: 535px;
  padding:10px;
}
.childContainer label{

   width: 100% !important;
}
.radioUnitTree {
  margin-left: -30px;
  margin-top: -20px;

}

.radioUnitTree  .PrivateSwitchBase-root-11 {
  padding: 5px !important;
}
.treeContianer{
  background-color: #eee;
  border-radius: 5px;
  overflow-y: scroll;
  min-height: 100px;
  max-height: 540px;
  margin-top: 20px;
}


.blankContent {
  font-weight: 700; 
  color: #D3D6DF; 
  font-size:14px;
  font-style: italic;
  margin-top:5px;
}
.blankContentwithcolor {
  font-weight: 700; 
  color: #63C2FF; 
  font-size:14px;
  font-style: italic;
  margin-top:5px;
}
.blankContentwithgreencolor {
  font-weight: 700; 
  color: green; 
  font-size:14px;
  font-style: italic;
  margin-top:15px;
}
@keyframes glowing {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 #63C2FF;
  }
  }
  
  @-webkit-keyframes glowing {
  0% {
    -webkit-box-shadow: 0 0 10px #63C2FF;
  }
  50% {
    -webkit-box-shadow: 0 0 40px #63C2FF;
  }
  100% {
    -webkit-box-shadow: 0 0 10px #63C2FF;
  }
}



@media (max-width: 1300px) {
  .unittreesection {   
    float: left;
    width: 49%;  
    vertical-align:top;
    padding-right: 10px;
    font-size: 14px;
  }
}

.accountRightSection {
  display: inline-block;
  width: 49%;  
  vertical-align:top;
  margin-top: 77px;
  font-size: 14px;
}

.groupingOption {
  margin: 20px 0px 0px 0px;
}

.accountDetails {
    font-weight: 700;
    color: "#D3D6DF";
    font-size: "14px";
    font-style: "italic";
    margin-top: "5px";
}

.inactiveContainer {
    background-color: #eee;
    border-radius: 5px;
    margin-top: 15px;
}

.firstNameInput div div {
  height: 40px;
}

.checkBox {
    padding:3px;
}

.sectionTitle {
    margin:  0px 0px 10px 0px; 
    color: #21394F;
 }

 .inputContainer {
    padding-top: 20px;
}

.inputContainer div div {
    height: 40px;
}

.treeContianer {
  background-color: #eee;
  border-radius: 5px;
  margin-top: 20px;
  padding: 5px 15px;
}


.emailResetIcon {   
  margin-top: 5px;   
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.buttonSection {
  display: flex;
  flex-direction: row-reverse;
  margin-right: -5px;
  margin-top: 20px;
}

.passwordChecklist {
  padding-top: 10px;
}

.passwordChecklist ul li {
  font-size: 12px;
  font-weight: 600;
}

.passwordChecklist ul li span{
 margin-left:5px
}

.restartButton {
  border:1px solid #63C2FF !important
}



.inputContainer a{
  color: #21394F;
}

.inputContainer  button
{
  margin: 0px !important;
  padding-left: 0px !important;
}
.ButtonContainer button
{
  margin: 0px !important;  
}
.uniqueOption {
  margin: 20px 0px 0px 0px;
}
.formControl {
  width:100%;
}


.formControl div svg{
  right: 12px;
}
.inputmidsize {
  width: 80%;
  padding: 7px;
}

.inputmidsize input {
  padding: 5px;
  background-color: #fff;
}

.groupingOptionRow td{
  line-height: 0;
  padding: 0px 0px 0px 5px;
}

.importfileContainer {
  background-color: #eee;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}
.importfileContainer span{ 
  padding: 3px 0 0 10px; 
  color: #21394F;
}
.sectionTitle {
  margin:  0px 0px 10px 0px; 
  color: #21394F;
}
.guideMeButton {
  display:flex;
  flex-direction:row-reverse;
  position:relative;
  top:-10px;
}
.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:103px;
  right:35px;
  width: 24px;
  height: 24px;
  position:absolute;
}
.labelText {  
  color: #21394F;
  font-weight: bold;
}
.labelTextNormal {  
  color: #21394F;
}
.label {
  color: #21394F;
}
.labelTextitalic {
  font-weight: 700;
  color: #D3D6DF;
  font-size: 14px;
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 15px;    
}
.paginationControlsContainer {
  display: flex;
  justify-content: center;
}

.emptycell {
  height: 4px;
  padding: 0px !important;
}