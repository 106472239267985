.inputField {
    margin-bottom: 30px;
}

.colorInput {
    height: 100px;
    width: 100px;
}

.finePrint {
    font-style: italic;
}

.themeUpdatedText {
    margin-top: 12px;
    margin-left: 8px;
    position: absolute;
    transition: opacity 1s ease-out;
}

.themeFinePrint {
    font-style: italic;
    color: grey;
}

.inputContainer {
    margin-bottom: 20px;
}

.input {
    width: 30%;
    min-width: 300px !important;
}

.dropZone {
    width: 100px;
    padding: 9px;
    height: 100px;
    /* margin: 0rem 0 2rem; */
    border-radius: 5px;
    cursor: pointer;
    display: grid;
    place-items: center;
    border: 3px dashed #63c3ff70;
}

.dropZoneOver {
    border: 3px solid #63c2ff;
}

.dragZoneExit {
    border: 3px dashed #63c3ff70;
}

.dropZoneError {
    border: 3px solid red !important;
}

.dropZonePrompts {
    text-align: center;
}

.dropZoneThumb {
    height: 100%;
    width: 100%;
    background-color: #ccc;
    overflow: hidden;
    background-size: cover;
    background-repeat: none;
    background-position: center;
    border-radius: 5px;
    position: relative;
}

.dropZoneThumb::after {
    content: attr(data-label);
    position: absolute;
    bottom: 0px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    width: 100%;
    background: rgba(0,0,0,0.5);
}

.hiddenInput {
    display: none;
}

.dropAreaForm {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
}