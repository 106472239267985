.courseListButton {
    margin: 5px;
    min-width: 125px;
    margin: 3px;
}

.ellipsis {
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
    -o-text-overflow: inherit;
}

.filterButtonContainer {
    margin-bottom: 10px;
    margin-top: 20px;
}

.courseTitleContainer {
    display: flex;
    align-items: center;
}

.courseListContainer {
    min-width: 692px;
    margin-top: 8px;
}

.courseListContainer div table tbody tr td nav div {
    margin-left: 30px !important;
    width: 24px !important;
    height: 24px !important;
}
/* 
.loadingGif {
    background: url('loading.gif') no-repeat center center;
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    z-index: 9999999;
} */

.courseName {
    font-weight: 500;
    margin-left: 10px;
}

.nameHeading {
    margin-left: 30px
}

.complete {
    color: #3BB18B;
}

.divider {
    border-top: 2px solid lightgray;
}

@media (max-width: 320px) {
    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }
 }
