.fillScreen{
    opacity:1;
    background-color:#e5e5e5;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:100;
}

.controls{
    position: fixed;
    top: 62px;
    right: 155px;
    z-index: 200;
}

.styles.acceptBtnContainer {
    text-align: center;
}