.navbar {
    background: #fff;
    z-Index: 10;
    position: fixed;
    width: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.navbar_MulSecModPlayer {
    background: #fff;
    z-Index: 10000;
    position: fixed;
    width: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}


