.fillScreen{
    opacity:1;
    background-color:#e5e5e5;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
    overflow: auto;
    /* overflow-y: scroll;  --to remove extra scrollbar on all course page*/
}