
 /*
  _____  ______          _____    __  __ ______ 
 |  __ \|  ____|   /\   |  __ \  |  \/  |  ____|
 | |__) | |__     /  \  | |  | | | \  / | |__   
 |  _  /|  __|   / /\ \ | |  | | | |\/| |  __|  
 | | \ \| |____ / ____ \| |__| | | |  | | |____ 
 |_|  \_\______/_/    \_\_____/  |_|  |_|______|
These are GLOBAL overides!! Please use CSS modules individual component styling.

*/

html, body, #app, #app>div {
  height: 100%
}

body {
  margin: 0px;
  background-color: #e6e6e6;
  /* stop the scroll bar adding width */
  overflow-y: auto; 
}

a {
color: blue;
text-decoration: none; /* no underline */
}

#root {
height: 100%;
}

.sample-class .introjs-tooltiptext{
color: rgba(0,0,0,0.9);
background-color:rgba(255,255,255,1);
}

.sample-class .introjs-button{
background-color:#63C2FF!important;
color:white;
border-radius: 8px!important;
} 

.sample-class .introjs-tooltip-header {
background-color:#fff;
border-radius: 10px 10px 0 0;
}

.introjs-tooltip {
  max-width: 500px !important;
  min-width: 400px !important;
  min-height: 50px !important;
  border-radius: 10px !important;
  height: auto  !important;
}
.introjs-arrow.bottom-right
{
  left: auto !important;
}

.introjs-helperLayer
{
  box-shadow: #63C2FF 0px 0px 1px 4px, rgb(00 00 00 / 50%) 0px 0px 0px 5000px !important;
}

.introjs-bullets {
  padding: 0px !important;
  height: 0px !important;
}

.introjs-bullets ul {
  top: 15px !important;
}

.introjs-arrow.top, .introjs-arrow.top-right, .introjs-arrow.right, .introjs-arrow.bottom, .introjs-arrow.top-middle, .introjs-arrow.top-right, 
.introjs-arrow.bottom-middle, .introjs-arrow.bottom-right, .introjs-arrow.right-bottom, .introjs-arrow.bottom-right, .introjs-arrow.left, .introjs-arrow.left-bottom{
  display: none !important;
}

.introjs-tooltip-title {
  padding: 0px 10px !important;
  color: black !important;
  font-weight: 600 !important;
}

.introjs-bullets ul li a.active {
  background: #63C2FF!important;
}
.introjs-skipbutton{
  padding: 0px 5px !important;
}

.introjs-tooltip-header
{
  padding: 10px !important;
}

.sample-class .introjs-bullets {
background-color:#fff;
}

.introjs-bullets ul {
position: relative;
top:40px;
}

.sample-class .introjs-tooltipbuttons {
background-color:#fff;
border-top:none;
border-radius: 0 0 10px 10px;
}

.table-head-text {
font-weight: 600;
}

/*___Used for setting table before exporting___*/
.hidden {
display: none !important;
}

.blackText {
color: black !important;
font-weight: 500 !important;
}
/*______________________________________________*/

* {
font-family: 'Open Sans', sans-serif;
}

h1, h2, h3 {
font-family: 'Open Sans', sans-serif;
font-weight: 400;
color: #21394F;
}

hr {
border-top: 1px solid #eee
}

::-webkit-scrollbar {
width: 10px;
height: 10px;
position: absolute;
z-index: -10 ;
}
::-webkit-scrollbar-button {
width: 0px;
height: 0px;
}
::-webkit-scrollbar-thumb {
background: #63c2ff;
border: 0px none #fff;
/* border-radius: 0px; */
}
::-webkit-scrollbar-thumb:hover {
background: #63ceff;
}
::-webkit-scrollbar-thumb:active {
background: #63ccff;
}
::-webkit-scrollbar-track {
background: #21394f;
border: 0px none #fff;
}
::-webkit-scrollbar-track:hover {
background: #35597a;
}
::-webkit-scrollbar-track:active {
background: #35597a;
}
::-webkit-scrollbar-corner {
background: transparent;
}


.MuiButton-outlinedSizeSmall
{
  padding: 0px 0px !important;
}
.MuiButton-outlinedPrimary
{
border: none !important;
}
.MuiButton-containedSizeSmall
{
padding: 6px 16px !important;
}

.highcharts-text-outline
{
stroke: none !important;
stroke-width: 0px !important;
}
.highcharts-data-table
{
    position: relative;
    background-color: white;
    z-index: 10;
    display: block;
    padding: 0 20px 0px 0;
}
.highcharts-data-table table {
  border-collapse: collapse;
  border-spacing: 0;
  background: white;
  min-width: 100%;
  margin-top: 10px;
  font-family: sans-serif;
  font-size: 0.9em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  border: 1px solid silver;
  padding: 0.5em;
  text-align: center;
}
.highcharts-data-table tr:nth-child(even), .highcharts-data-table thead tr {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #eff;
}
.highcharts-data-table caption {
  border-bottom: none;
  font-size: 1.1em;
  font-weight: bold;
  background-color: white;
}

.MuiTypography-body1 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  width: -webkit-fill-available;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 5px 0px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 20px;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  padding-top: 0px;
}

.highcharts-container 
{
  text-align: center !important;
  width: 100% !important;
}