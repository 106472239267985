.cdpScore {
    font-weight: 900;
    color: #5ab5ee;
    display: block;
}

.cdpScore:hover {
    cursor: pointer;
}

.exportBtn {
    margin-left: 0px !important;
}

.exportBtnContainer {
    margin-bottom: 5px;
    text-align: right;
}

.passed {
    color: #3bb18b
}

.failed {
    color: #fa0200
}
.onetimecourse {
  color: #3bb18b;
  font-weight: bold;
}
.exportLoader {
    background-color: #eee;
    position: absolute;
    z-index: 1;
    margin-left: -4px !important;
    margin-top: -5px !important;
}

.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}


.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:100px;
  right:35px;
  width: 24px;
  height: 24px;
  position:absolute;
}

@keyframes glowing {
0% {
  transform: scale(1);
}
70% {
  transform: scale(1);
  box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
}
100% {
  transform: scale(1);
  box-shadow: 0 0 0 0 #63C2FF;
}
}

@-webkit-keyframes glowing {
0% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
50% {
  -webkit-box-shadow: 0 0 40px #63C2FF;
}
100% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
}