.container {
    text-align: center;
    max-height: 80vh;
    background-color: white;   
    overflow-y: auto;
    margin-top: 20px;
}

.releaseNoteContainer {
    text-align: left;
    padding: 20px;    
}

.subreleaseNoteContainer {
    margin-left: 20px;
    text-align: left;
    padding: 5px;
}

.listTitle {
    font-weight: 600;
    list-style-type:none;
}

.logoContainer > svg {
    width: 130px;
}