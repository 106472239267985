.title {
    margin: 0px
}
.descriptionParagraph {
    font-size: 1.2em;
    margin-top: 0px;
}

.moduleBar {
    background-color: white;
    width: fit-content;
    height: 36px;
    margin-bottom: -6px;
    padding: 0px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%), 0 3px 6px rgb(0 0 0 / 15%);
    border-radius: 8px 8px 0 0;
}

.moduleBar > div  {
    display: inline-block;
}

.moduleTitle {
    margin: 2px 0px;
}

.moduleDescription {
    width: 50%;
}

.moduleDates {
    font-size: 12px;
    font-weight: 600;
    padding: 6px 11px;
}

.moduleDates > span {
    margin-right: 10px;
    font-style: italic;
}

.moduleListButton {
    margin: 5px;
}

.cpdPanelContainer {
    flex: 2;
}

.rowTitle {
    font-weight: 600;
}

.passed {
    color: #3bb18b
}

.failed {
    color: #fa0200
}

.courseCompletionPanelContainer {
    flex: 1;
    margin-right: 1%;
    /* max-width: 33%; */
}

.guideMeButtonSpecific {
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:103px;
    right:110px;
    width: 24px;
    height: 24px;
    position:absolute;
  }
  
  @keyframes glowing {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 #63C2FF;
  }
  }
  
  @-webkit-keyframes glowing {
  0% {
    -webkit-box-shadow: 0 0 10px #63C2FF;
  }
  50% {
    -webkit-box-shadow: 0 0 40px #63C2FF;
  }
  100% {
    -webkit-box-shadow: 0 0 10px #63C2FF;
  }
  }

  .tabContainer {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
    border-radius: 0px 0px 8px 8px;
    min-height: 80px;
  }
  