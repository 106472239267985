.modalBackground {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: #0000009e; 
    position: fixed;
    z-index: 1000;
    cursor: pointer;
    overflow: hidden;
    overflow-y: scroll;
}

.modalContentWrapper {
    padding: 20px;
    z-index: 1001;
    cursor: default
}

.modalCard {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    z-index: 10000;
}

.exitButtonContainer {
    justify-content: flex-end;
    display: flex;
    position: absolute;
    width: 100%;
    cursor: default
}

.exitButtonContainer > svg {
    cursor: pointer;
    color: #21394F;
}

.exitButtonContainer > svg:hover {
    fill: #63C2FF;
}


@media (max-width: 500px) {
.modalCard {
    width: 50%;
    padding-right: 30%;
    padding-bottom: 10%;
    }    
 }
