.mainWrapper{  
    min-height: 130px;   
    height: auto;
    clear: both;
    margin-top: 20px;
  }
  .Wrapper{   
    padding: 20px;
  }

  .orgName {
    background-color: #fff;
  }
  .inputContainer {
    margin-bottom: 15px;
  }

  .notesContainer {
    margin-bottom: 40px;
  } 
  .lableContainer {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  
  .inputContainer a{
    color: #21394F;
  }

  .disabledText {
    color: #ccc;
  }
  
  .inputContainer  button
  {
    margin: 0px !important;
    padding-left: 0px !important;
  }
  .ButtonContainer button
  {
    margin: 0px !important;  
  }
  .uniqueOption {
    margin: 20px 0px 0px 0px;
  }
  .formControl {
    width:100%;
  }
  .input {
    width: 100%;
    min-width: 300px !important;
    height: 40px;
  }
  .formControl div svg{
    right: 12px;
  }
  .inputmidsize {
    width: 80%;
    padding: 7px;
  }

  .inputmidsize input {
    padding: 5px;
    background-color: #fff;
  }

  .groupingOptionRow td{
    line-height: 0;
    padding: 0px 0px 0px 5px;
  }

  .importfileContainer {
    background-color: #eee;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .importfileContainer span{ 
    padding: 3px 0 0 10px; 
    color: #21394F;
  }
  .sectionTitle {
    margin:  0px 0px 10px 0px; 
    color: #21394F;
  }
  .guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
  }
  .guideMeButtonSpecific {
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:103px;
    right:35px;
    width: 24px;
    height: 24px;
    position:absolute;
  }
  .guideMeButtonback{
    animation: glowing 3s normal 3;
    border-radius: 100%;
    top:100px;
    right:100px;
    width: 24px;
    height: 24px;
    position:absolute;
  }
  .labelText {  
    color: #21394F;
    font-weight: bold;
  }

  .labelTextDisabled {  
    color: #ccc;
    font-weight: bold;
  } 
  .labelTextNormal {  
    color: #21394F;
  }
  .label {
    color: #21394F;
  }
  .labelTextitalic {
    font-weight: 700;
    color: #D3D6DF;
    font-size: 14px;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 15px;    
  }

  .labelTextSmallNormal {
    color: #21394F;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 35px;
  }
  .paginationControlsContainer {
    display: flex;
    justify-content: center;
}

.returnIcon{
  color: #fff;
  width: 55px;
  height: 37px;
  background: #F2B05C;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-top: 5px;
  }

.emptycell {
  height: 4px;
  padding: 0px !important;
}

.tabContainer {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15);
  border-radius: 0px 0px 8px 8px;
  min-height: 80px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.courseModuleNameSearch { 
  padding: 2px;
  flex-grow: 1;
}

.courseModuleNameSearch div input { 
  padding: 2px  15px;
}

.courseModuleNameButtons {     
  width: 132px;
  padding-left: 4px;
  float: right;
}

.search_longtextbox_and_buttons_container
{
    width: 100%;
    display: absolute;
}

.monthbasedexpiryleft{
  padding-top: 10px;  
 
}
.datebasedexpiryleft{
  padding-top: 10px;  
  padding-left: 30px;
}


.expandContainer {  
  border-radius: 5px;
  margin-top: 10px;
  min-width: 510px;
  padding:10px;
  display: flex;
  justify-content: space-between;
  height: 15px;
  position: relative;
  top: -5px;
}

.lowerButtonPanel {
  display: flex;
  flex-direction: row-reverse;
  margin-right: -5px;
  margin-top: 20px;
}
.listField div div {
  height: 40px;
  background-color: white;
}
.listField {
   
  flex-grow: 2;
}

.leftrightPadding10 {
   padding: 0 10px;
}

.icon_and_text_container_leftshift {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  padding: 10px;
  margin-left: -20px;
}
.icon_and_text_container_icon {
  flex: 1;
  padding: 8px 10px 12px 10px;
  width: 20px;
  height: 20px;
  max-width: 20px;
}
.icon_and_text_container_text {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  width: auto;
  height: 20px;
  margin-top: -2px;
  margin-left: -20px;
}


@media only screen and (min-width: 200px){
  .monthbasedexpiryright {
    padding-top: 10px;    
  }
  .datebasedexpiryright {
    padding-left: 30px;
    padding-top: 10px ;
  }
}
@media only screen and (min-width: 1024px) {
  .monthbasedexpiryright {
    padding-left: 30px;
    padding-top: 10px;
  }
  .datebasedexpiryright {
    padding-left: 30px;
    padding-top: 10px;
  }
}
@keyframes glowing {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 #63C2FF;
  }
}
  
@-webkit-keyframes glowing {
0% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
50% {
  -webkit-box-shadow: 0 0 40px #63C2FF;
}
100% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
}



.sortArrowHidden {
    opacity: 0 !important;
}

.sortArrowAccending {
    transform: rotate(0deg);
    transition: transform 600ms;
    display: grid;
    place-items: center;
}

.columnHeadArea {
    place-items: center;
    display: flex;
    flex-direction: column; 
    left: 5px;
    margin-left: 5px;
}

.sortArrowDescending {
    transform: rotate(180deg);
    transition: transform 600ms;
    display: grid;
    place-items: center;
}

.tableHeadCellContainer {
  /* width: 90%; */
  display: inline-flex;
  align-items: center;
}


.deleteIcon {
  margin: 5px;
  width: 55px;
  height: 37px;
  background: red;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    float: right;
}

.guideMeButtonSpecificaddemailrule {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:103px;
  right:100px;
  width: 24px;
  height: 24px;
  position:absolute;
}

.lowerMiddleButtonPanel {
  text-align: center;
  margin-top: 20px;
}


.noResultsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: center;
  font-weight: 550;
}
.modifylinkleft{
  padding-top: 10px;  
  padding-left: 10px;
}

#timeline tr td {
  text-align: center;
}

.timelinechart {
  padding: 0px;
  margin: 0px;
  border: 0;
  border-collapse: collapse;
}

.rowboderbottom {
  border-bottom: 1px solid #000;
  padding: 5px;
  color: #21394F;
  text-align: center;
  min-width: 60px;
  min-height: 61px;
}

.rowboderleft {
  border-left: 1px solid #000;
}

.ieStartDatevalue {
margin-top: 1px;
border-radius: 30px 0px 0px 30px;
-webkit-border-radius: 30px 0px 0px 30px;
-moz-border-radius: 30px 0px 0px 30px;
background: #20b786;
}

.ieEndDatevalue {
  background-color: #20b786;
  margin-top: 1px;
  border-radius: 0px 30px 30px 0px;
-webkit-border-radius: 0px 30px 30px 0px;
-moz-border-radius: 0px 30px 30px 0px;
}

.ieDatevalue {
  background-color: #20b786;
  margin-top: 1px;
  color: #21394F;
}

.oneDayCourse {
  border: 5px solid #20b786;
  background-color: #20b786;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #21394F;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  padding: 10px !important;
}

.blankrow {
  height: 1px;
  background-color: #fff;
}

.numberRow {
  text-align: center;
  padding-top: 2px;
}

.circlecolumns {
  padding: 0px 10px;
}

.preexpirydate {
  border: 5px solid #F2B05C;
  background-color: #F2B05C;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #21394F;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  padding: 10px;
}

.postexpirydate {
  border: 5px solid #ef7071;
  background-color: #ef7071;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  padding: 10px;
}

.managernotificationdate {
  border: 5px solid #63c2ff;
  background-color: #63c2ff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  padding: 10px;
}

.postExpiryManagernotificationdate {
  border: 7px solid #ef7071;
  /* background: linear-gradient(#ef7071 50%, #63c2ff 50%); */
  background: #63c2ff;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  padding: 10px;
}

.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
ul.GraphNotation {
  padding-left: 200px;
}

.GraphDetails {
  clear: both;
}

ul.GraphNotation li{
  float:left; 
  min-width: 215px; 
}
.notationText {
  font-size: 16px;
}
ul.GraphNotation li span{
  font-size: 16px;
  position: absolute;
  padding-top: 19px;
}  
  
.courseDuration::marker {
  color: #20b786;
  font-size: 40px;
}
.preExpiry::marker {
  color: #F2B05C;
  font-size: 40px;
}
.postExpiry::marker {
  color: #ef7071;
  font-size: 40px;
}
.mgrNotify::marker {
  color: #63c2ff;
  font-size: 40px;
}
.courseDurationList::marker {
  color: #20b786;
  font-size: 25px;
}
.preExpiryList::marker {
  color: #F2B05C;
  font-size: 25px;
}
.postExpiryList::marker {
  color: #ef7071;
  font-size: 25px;
}
.mgrNotifyList::marker {
  color: #63c2ff;
  font-size: 25px;
}
.inputContainer  span
{
  padding-left: 3px !important;
}


.blankContent {
  font-weight: 700; 
  color: #D3D6DF; 
  font-size:14px;
  font-style: italic;
  margin-top:5px;
}
.blankContentwithcolor {
  font-weight: 700; 
  color: #63C2FF; 
  font-size:14px;
  font-style: italic;
  margin-top:5px;
}

.blankContentwithgreencolor {
  font-weight: 700; 
  color: green; 
  font-size:14px;
  font-style: italic;
  margin-top:15px;
}

.customisedpolicyconfig {
  width: 100% !important;
  padding-left: 0px !important;
}

.customisedpolicyconfig > div {
  margin-top: -45px !important;
  margin-left: 35px !important;
  width: -webkit-fill-available !important;
}
