.barContainer {
    min-width: 280px;
}

.background {
    height: 10px;
    width: 100%;
    background-color: #c4c4c4;
    border-radius: 10px;
}

.bar {
    width: 50%;
    border-radius: 10px;
    height: 100%;
}

.passmark {
    background-color: grey;
    width: 8px;
    height: 40px;
    top: -25px;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
}

.scoreDisplay {
    font-weight: 900;
    font-size: 16px;
    margin-top: -15px;
    padding-bottom: 10px;
}