.container {
    width: 600px;
  }

.container .modalcontent {
    display: flex !important;
}

.modalTextWrapper {
    width: 100%;
    padding: 2%;
}

.modalTextWrapper > ul {
    list-style: none;
    padding: 0px; 
}
.paneltitle {
    margin-top: 16.5px;
}

.finePrintContainer {
    padding: 20px;
    text-align: center;
}

.finePrintContainer a {
    color: #039be5;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}
.contactsupportbtn {
    margin: 0px !important;
    height: 45px !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-align: center;
    color: #fff !important;
}
.contactsupportbtn a{
    color: #fff;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.contactNumber {
    margin-bottom: 5px;
}

.contactNumber > img {
    height: 30px;
    vertical-align: middle;
}

.contactNumber > span {
    padding: 4px;
    vertical-align: middle;
}
.contactNumber > svg {
    height: 30px;
    vertical-align: middle;
}

.center
{
    text-align: center;
}
h5 {
    font-size: 20px;
    line-height: 110%;
    margin: 1.0933333333rem 0 0.656rem 0;
    font-weight: 400;
}

h6 {
   font-size: 16px;
    line-height: 110%;
    margin: 0.7666666667rem 0 0.46rem 0;
    font-weight: 400;
}

.tandccontent {
    padding: 2%
}

.tandctitle {
    margin: 2% !important
}

.termsAndConditionsText {
    top: -30px !important;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
}