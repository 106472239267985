.lockIcon {
    margin-left: -33px;
    margin-top: 15px;
}

.input {
    width: 30%;
    min-width: 300px !important;
}

.inputBackground {
    color: red;
}
.returnIcon{
  width: 65px;
  height: 47px;
  background: #F2B05C;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }

  .proceedIcon{
    width: 65px;
    height: 47px;
    background: #63C2FF;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
      align-items: center;
      justify-content: center;
    }

.disabledInput {
    width: 30%;
    min-width: 300px !important;
    background-color: rgba(0,0,0,0) !important;;
}

.inputContainer {
    margin-bottom: 20px;
}

.saveButtonContainer {
    display: inline-flex
}

.loaderContainer {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
}

.passwordFinePrint {
    font-style: italic;
    color: grey;
}

.selectDropdown {
    min-width: 300px !important;
    background-color: white;
    height: 56px;
    padding: 18.5px 14px;
}

.profileUpdatedText {
    margin-left: 8px;
    position: absolute;
    transition: opacity 1s ease-out;
}

.nameDisplayContainer {
    display: inline-flex;
    align-items: center;
}

.nameDisplayText {
    font-size: 1.1rem;
}

.guideMeButton {
    display:flex;
    flex-direction:row-reverse;
    position:relative;
    top:-10px;
}
.green{
  font-weight: bold;
  color: green;
}

/* .guideMeButtonSpecific {
  animation: glowing 20s infinite;
}

@keyframes glowing {
0%,10%,20% {
  transform: scale(.9);
  box-shadow: 0 0 10px #63C2FF;
}
5%,15%,25% {
  transform: scale(1);
  box-shadow: 0 0 40px #63C2FF;
}
30%,100% {
  transform: scale(.9);
  box-shadow: 0 0 10px #63C2FF;
}
} */

.guideMeButtonSpecific {
  animation: glowing 3s normal 3;
  border-radius: 100%;
  top:-45px;
  right:85px;
  width: 24px;
  height: 24px;
  position:relative; 
}
@keyframes glowing {
0% {
  transform: scale(1);
}
70% {
  transform: scale(1);
  box-shadow: 0 0 0 15px rgba(99, 194, 255, 0.3);
}
100% {
  transform: scale(1);
  box-shadow: 0 0 0 0 #63C2FF;
}
}

@-webkit-keyframes glowing {
0% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
50% {
  -webkit-box-shadow: 0 0 40px #63C2FF;
}
100% {
  -webkit-box-shadow: 0 0 10px #63C2FF;
}
}