.courseIconSmall {
    height: 74px;
    min-width: 100px;
    display: inline-block;
    border-radius: 5px;
}

.courseIconSmall svg {
    margin: auto;
    display: block;
    height: 100%;
    width: 40px;
}

.courseIconSmallOneTime {
    height: 74px;
    min-width: 100px;
    display: inline-block;
    border-radius: 5px;
}

.courseIconSmallOneTime svg {
    margin: auto;
}

@media only screen and (max-width: 950px) {
    .courseIconSmall {
        display: none;
    }
    
    .courseIconSmall svg {
        display: none;
    }
 }
